// Generated by Framer (38fb4ea)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["PFgaGgL1i"];

const serializationHash = "framer-1i9Vg"

const variantClassNames = {PFgaGgL1i: "framer-v-jk6162"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, image1, link, width, ...props}) => { return {...props, A9JtMOLdI: image1 ?? props.A9JtMOLdI, TOYYJiQFy: link ?? props.TOYYJiQFy} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;link?: string;image1?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, TOYYJiQFy, A9JtMOLdI, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "PFgaGgL1i", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={TOYYJiQFy}><motion.a {...restProps} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-jk6162", className, classNames)} framer-p9r7e2`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"PFgaGgL1i"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><Image background={{alt: "", fit: "fill", sizes: componentViewport?.width || "100vw", ...toResponsiveImage(A9JtMOLdI)}} className={"framer-hs4h5k"} data-framer-name={"Photo 1"} layoutDependency={layoutDependency} layoutId={"fq_fLTIcs"} style={{borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10}}/><Image background={{alt: "", fit: "fill"}} className={"framer-1cfrpbt"} data-framer-name={"Photo 2"} layoutDependency={layoutDependency} layoutId={"rAmMDQHWY"} style={{borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10}}/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-1i9Vg.framer-p9r7e2, .framer-1i9Vg .framer-p9r7e2 { display: block; }", ".framer-1i9Vg.framer-jk6162 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: wrap; gap: 40px; height: 286px; justify-content: flex-start; overflow: visible; padding: 0px; position: relative; text-decoration: none; width: 422px; }", ".framer-1i9Vg .framer-hs4h5k, .framer-1i9Vg .framer-1cfrpbt { aspect-ratio: 1.4736842105263157 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 286px); left: 0px; position: absolute; right: 0px; top: 0px; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-1i9Vg.framer-jk6162 { gap: 0px; } .framer-1i9Vg.framer-jk6162 > * { margin: 0px; margin-left: calc(40px / 2); margin-right: calc(40px / 2); } .framer-1i9Vg.framer-jk6162 > :first-child { margin-left: 0px; } .framer-1i9Vg.framer-jk6162 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 286
 * @framerIntrinsicWidth 422
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"TOYYJiQFy":"link","A9JtMOLdI":"image1"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerkUYzRBPGz: React.ComponentType<Props> = withCSS(Component, css, "framer-1i9Vg") as typeof Component;
export default FramerkUYzRBPGz;

FramerkUYzRBPGz.displayName = "3D - Rotate - CMS";

FramerkUYzRBPGz.defaultProps = {height: 286, width: 422};

addPropertyControls(FramerkUYzRBPGz, {TOYYJiQFy: {title: "Link", type: ControlType.Link}, A9JtMOLdI: {title: "Image 1", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerkUYzRBPGz, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})